
	import { defineComponent, ref, onMounted, toRefs } from "vue";
	import http from "../services/http";
	import store from "../services/store";
	import { format } from "date-fns"

	export default defineComponent({
		setup() {
			const tickets = ref([]);
			const { user, id } = toRefs(store.state);

			function fetchTickets(page = 1) {
				http.get(`/api/tickets?user_id=${id.value}&page=${page}`).then(
					(res: any) => {
						tickets.value = res.data;
					}
				);
			}

			function fD(d: any, f = "PPPP") {
				return format(new Date(d), f);
			}

			onMounted(() => {
				fetchTickets();
			});

			return { tickets, user, fD };
		},
	});
