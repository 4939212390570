<template>
	<div class="ng-content-wrap">
		<div class="ng-block-head ng-block-head-lg">
			<div class="ng-block-head-sub d-print-none">
				<span>My Tickets</span>
			</div>
			<div class="ng-block-between-md g-4">
				<div class="ng-block-head-content">
					<h2 class="ng-block-title fw-normal">Support Tickets</h2>
					<div class="ng-block-des d-print-none">
						<p>
							Here are all of your support requests that you
							already sent.
						</p>
					</div>
				</div>
				<div class="ng-block-head-content">
					<ul class="ng-block-tools g-4 flex-wrap">
						<li class="order-md-last">
							<router-link
								to="/contact"
								class="btn btn-white btn-dim btn-outline-primary"
							>
								<span>Submit Ticket</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="ng-block">
			<div class="card card-bordered">
				<table class="table table-tickets">
					<thead class="tb-ticket-head">
						<tr class="tb-ticket-title">
							<th class="tb-ticket-id">
								<span>Ticket</span>
							</th>
							<th class="tb-ticket-desc">
								<span>Subject</span>
							</th>
							<th class="tb-ticket-date tb-col-md">
								<span>Submited</span>
							</th>
							<th class="tb-ticket-seen tb-col-md">
								<span>Last Seen</span>
							</th>
							<th class="tb-ticket-status">
								<span>Status</span>
							</th>
							<th class="tb-ticket-action">&nbsp;</th>
						</tr>
					</thead>

					<tbody class="tb-ticket-body">
						<tr
							class="tb-ticket-item is-unread"
							v-for="t in tickets.data"
							:key="t.id"
						>
							<td class="tb-ticket-id">
								<router-link :to="`/ticket/${t.id}`">
									{{ t.id }}
								</router-link>
							</td>
							<td class="tb-ticket-desc">
								<router-link :to="`/ticket/${t.id}`">
									<span class="title">
										{{ t.subject }}
									</span>
								</router-link>
							</td>
							<td class="tb-ticket-date tb-col-md">
								<span class="date">
									{{ fD(t.created_at) }}
								</span>
							</td>
							<td class="tb-ticket-seen tb-col-md">
								<span class="date-last"
									><em
										class="icon-avatar bg-danger-dim icon ni ni-user-fill ng-tooltip"
										title="Support Team"
									></em>
									{{ fD(t.updated_at, 'yyy-MM-dd') }}
									<span class="d-none d-xl-inline"
										>10:20am</span
									></span
								>
							</td>
							<td class="tb-ticket-status">
								<span class="badge badge-success">Open</span>
							</td>
							<td class="tb-ticket-action">
								<router-link
									:to="`/ticket/${t.id}`"
									class="btn btn-icon btn-trigger"
								>
									<em class="icon ni ni-chevron-right"></em>
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="ng-block ng-block-lg">
			<div class="card card-bordered border-primary">
				<div class="card-inner">
					<div class="ng-cta">
						<div class="ng-cta-block">
							<div class="ng-cta-img">
								<em class="icon icon-circle ni ni-call"></em>
							</div>
							<div class="ng-cta-text">
								<p>
									You can also call directly
								</p>
							</div>
						</div>
						<div class="ng-cta-action">
							<a href="tel:254204404993" class="btn btn-primary">
								Call Support
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { defineComponent, ref, onMounted, toRefs } from "vue";
	import http from "../services/http";
	import store from "../services/store";
	import { format } from "date-fns"

	export default defineComponent({
		setup() {
			const tickets = ref([]);
			const { user, id } = toRefs(store.state);

			function fetchTickets(page = 1) {
				http.get(`/api/tickets?user_id=${id.value}&page=${page}`).then(
					(res: any) => {
						tickets.value = res.data;
					}
				);
			}

			function fD(d: any, f = "PPPP") {
				return format(new Date(d), f);
			}

			onMounted(() => {
				fetchTickets();
			});

			return { tickets, user, fD };
		},
	});
</script>
